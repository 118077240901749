<template>
  <ModalTemplate
    :showModal="isModalActive"
    width="839px"
    @closeModal="closeModal"
  >
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18">Edit Alamat Toko</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="body-edit">
        <div class="padding-body">
          <div class="flex-center-vertical is-justify-content-space-between mb-4">
            <div class="input-label">
              <p class="averta-bold">Alamat Toko <span class="color-red">*</span></p>
            </div>
            <div class="c-w-85 is-relative" @click="addAddressByMaps">
              <b-input
                readonly="readonly"
                onfocus="this.blur();"
                placeholder="Masukkan alamat toko"
                class="input-search-2 input-none pr-5"
                icon-right-clickable
                @icon-right-click="addAddressByMaps"
                v-model="data.address"
              ></b-input>
              <div class="location-icon">
                <Location color="#868686" size="24" />
              </div>
            </div>
          </div>
          <div class="flex-center-vertical is-justify-content-space-between mb-4">
            <div class="input-label flex-shrink-0">
              <p class="averta-bold">Detail Alamat <span class="color-red">*</span></p>
            </div>
            <div class="c-w-85">
              <textarea
                style="resize: none;"
                rows="3"
                class="textarea input-search-2 input-none"
                placeholder="Detail alamat lebih lengkap"
                v-model="data.detail_address"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="button-select-wrapper mt-5">
          <div class="is-flex flex-center-vertical is-justify-content-flex-end">
            <ButtonApp @click="handleConfirmAddress">
              <p class="averta-bold py-1 px-5">Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { Location } from '@/components/icons'
// import { alertErrorMessage } from '@/helpers'

export default {
  name: 'EditAddressStore',
  components: {
    Location,
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalTemplate: () => import('@/components/modal/ModalTemplate.vue')
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    refreshData: {
      type: Boolean,
      default: false
    },
    dataAddress: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      dataModal: null,
      data: this.dataAddress
    }
  },
  computed: {
    ...mapGetters({
      dataMaps: 'client/getDataMaps'
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    handleConfirmAddress () {
      this.$emit('confirmChangeAddress')
    },
    addAddressByMaps () {
      this.$emit('showMaps')
    }
  },
  watch: {
    showModal (value) {
      this.isModalActive = value
    },
    dataMaps: {
      handler (value) {
        this.data.address = value.data.address
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.body-edit {
  background: white;
  border-radius: 0px 0px 12px 12px;
}

.button-select-wrapper {
  height: 100%;
  padding: 10px;
  box-shadow: 0px -3px 30px 0px #00000012;
}

.padding-body {
  background: #fff;
  padding: 20px 40px;
}

.location-icon {
  position: absolute;
  right: 5px;
  top: 7px;
}
</style>
